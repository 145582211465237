<template>
  <card>
    <form @submit.prevent="login">
      <base-input
        :label="$t('user.mail')"
        type="email"
        placeholder="Enter email"
        v-model="mail"
      />
      <base-input
        :label="$t('user.password')"
        type="password"
        placeholder="Enter password"
        v-model="password"
      />
      <base-button block type="primary" native-type="Submit">{{$t("user.submit")}}</base-button>
    </form>
  </card>
</template>
<script>
import userService from "@/services/user";

export default {
  components: {},
  data() {
    return {
      mail: '',
      password: ''
    }
  },
  methods: {
    async login() {
      await userService.login(this.mail, this.password);
    }
  },
};
</script>
<style>
</style>
